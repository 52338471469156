// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-event-sponsorship-js": () => import("./../../../src/pages/event-sponsorship.js" /* webpackChunkName: "component---src-pages-event-sponsorship-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-chapter-1-js": () => import("./../../../src/pages/onboarding/chapter1.js" /* webpackChunkName: "component---src-pages-onboarding-chapter-1-js" */),
  "component---src-pages-onboarding-chapter-2-js": () => import("./../../../src/pages/onboarding/chapter2.js" /* webpackChunkName: "component---src-pages-onboarding-chapter-2-js" */),
  "component---src-pages-onboarding-chapter-3-js": () => import("./../../../src/pages/onboarding/chapter3.js" /* webpackChunkName: "component---src-pages-onboarding-chapter-3-js" */),
  "component---src-pages-onboarding-chapter-4-js": () => import("./../../../src/pages/onboarding/chapter4.js" /* webpackChunkName: "component---src-pages-onboarding-chapter-4-js" */),
  "component---src-pages-onboarding-chapter-5-js": () => import("./../../../src/pages/onboarding/chapter5.js" /* webpackChunkName: "component---src-pages-onboarding-chapter-5-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-demos-template-js": () => import("./../../../src/templates/demosTemplate.js" /* webpackChunkName: "component---src-templates-demos-template-js" */)
}

